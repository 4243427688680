<template>
  <div style="position: relative" class="w-100 d-flex flex-column">
    <loader-spinner no-border-radius-in-overlay centered v-if="isShowOverPageSpinner"></loader-spinner>
    <div class="d-flex w-100 flex-column justify-content-end align-items-end mb-1">
      <WButton info sm
        v-if="!isDisable"
        @click.stop.prevent="addButtonClickHandler"
        :disabled="isDisable"
      >
        {{ $t('SNMP.add') }}
      </WButton>
    </div>
    <div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>{{ $t('SNMP.SNMPHosts.ipAddress') }}</th>
            <th>{{ $t('SNMP.SNMPHosts.port') }}</th>
            <th>{{ $t('SNMP.SNMPHosts.version') }}</th>
            <th>{{ $t('SNMP.SNMPHosts.type') }}</th>
            <th v-if="showAllSettingsOnSNMPPartitionPages">{{ $t('SNMP.SNMPHosts.community') }}</th>
            <th v-if="!showAllSettingsOnSNMPPartitionPages">{{ $t('SNMP.SNMPHosts.community') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="host in hosts" :key="host.id" @click.stop.prevent="tableStringClickHandler(host)">
            <td>{{ host.ip_address }}</td>
            <td>{{ host.port }}</td>
            <td>{{ getVersionForDisplayWithI18n(host.version) }}</td>
            <td>{{ getTypeForDisplayWithI18n(host.type) }}</td>
            <td v-if="showAllSettingsOnSNMPPartitionPages">{{ getCommunityStringNameById(host.community_string) }}</td>
            <td v-if="!showAllSettingsOnSNMPPartitionPages">{{ host.community_string }}</td>
          </tr>
          <tr v-if="!hosts.length">
            <td colspan="5" class="no-data-row">
              <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <modal
      v-if="isModalOpen"
      :backdrop="false"
      :title="isNew ? this.$t('SNMP.SNMPHosts.editHostsHeader') : this.$t('SNMP.SNMPHosts.editHostsHeader')"
      class="modal-info"
      closeBtn
      effect="fade/zoom"
      v-model="isModalOpen"
    >
      <h4 slot="modal-title" class="modal-title">
        {{ isNew ? this.$t('SNMP.SNMPHosts.editHostsHeader') : this.$t('SNMP.SNMPHosts.editHostsHeader') }}
      </h4>
      <!--      ip-->
      <div class="form-group">
        <label class="required-mark" for="snmp-hosts-ip">{{ $t('SNMP.SNMPHosts.ipAddress') }}:</label>
        <input
          :data-vv-as="$t('SNMP.SNMPHosts.ipAddress')"
          v-validate="'required|snmpHostIP'"
          data-vv-validate-on="none"
          @input="removeValidationErrorByName('snmp-hosts-ip')"
          type="text"
          :disabled="isDisable"
          v-model="updatedHost.ip_address"
          name="snmp-hosts-ip"
          id="snmp-hosts-ip"
          :placeholder="$t('SNMP.SNMPHosts.ipAddress')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-hosts-ip') }"
        />
        <span v-show="errors.has('snmp-hosts-ip')" class="help is-danger">
          {{ errors.first('snmp-hosts-ip') }}
        </span>
      </div>
      <!--     version -->
      <div class="form-group">
        <label for="snmp-host-version">{{ $t('SNMP.SNMPHosts.version') }}:</label>
        <select
          :data-vv-as="$t('SNMP.SNMPHosts.version')"
          data-vv-validate-on="input"
          type="text"
          :disabled="isDisable"
          v-model="updatedHost.version"
          name="snmp-host-version"
          id="snmp-host-version"
          :placeholder="$t('SNMP.SNMPHosts.version')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-host-version') }"
        >
<!--          <option key="notSelected" value="">-->
<!--            {{ $t('SNMP.notSelected') }}-->
<!--          </option>-->
          <option :key="version" v-for="version in Object.values(versions)" :value="version">
            {{ getVersionForDisplayWithI18n(version) }}
          </option>
        </select>
        <span v-show="errors.has('snmp-host-version')" class="help is-danger">
          {{ errors.first('snmp-host-version') }}
        </span>
      </div>

      <!--     community -->
      <div class="form-group" v-if="showAllSettingsOnSNMPPartitionPages">
        <label class="required-mark" for="snmp-host-community">{{ $t('SNMP.SNMPHosts.community') }}:</label>
        <select
          :data-vv-as="$t('SNMP.SNMPHosts.community')"
          v-validate="'required'"
          data-vv-validate-on="input"
          type="text"
          :disabled="isDisable"
          v-model="updatedHost.community_string"
          name="snmp-host-community"
          id="snmp-host-community"
          :placeholder="$t('SNMP.SNMPHosts.community')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-host-community') }"
        >
          <option key="notSelected" value="">
            {{ $t('SNMP.notSelected') }}
          </option>
          <option
            :key="selectedCommunityStringForUpdatedHostWhenOpenEditModal"
            v-if="!isNew && !communityStrings.find(item => item.id === selectedCommunityStringForUpdatedHostWhenOpenEditModal)"
            :value="selectedCommunityStringForUpdatedHostWhenOpenEditModal">
            {{ selectedCommunityStringForUpdatedHostWhenOpenEditModal }}
          </option>
          <option :key="communityString.id" v-for="communityString in communityStringsSortedByName" :value="communityString.id">
            {{ communityString.community_name }}
          </option>
        </select>
        <span v-show="errors.has('snmp-host-community')" class="help is-danger">
          {{ errors.first('snmp-host-community') }}
        </span>
      </div>

      <div class="form-group" v-if="!showAllSettingsOnSNMPPartitionPages">
        <label class="required-mark" for="snmp-host-community">{{ $t('SNMP.SNMPHosts.community')
          }}:</label>
        <input
          :data-vv-as="$t('SNMP.SNMPHosts.community')"
          v-validate="'required'"
          data-vv-validate-on="none"
          @input="removeValidationErrorByName('snmp-host-community')"
          type="text"
          :disabled="isDisable"
          v-model="updatedHost.community_string"
          name="snmp-host-community"
          id="snmp-host-community"
          :placeholder="$t('SNMP.SNMPHosts.community')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-host-community') }"
        />
        <span v-show="errors.has('snmp-host-community')" class="help is-danger">
          {{ errors.first('snmp-host-community') }}
        </span>
      </div>

      <!--      port -->
      <div class="form-group">
        <label for="snmp-user-host-port">{{ $t('SNMP.SNMPHosts.port') }}:</label>
        <input
          :data-vv-as="$t('SNMP.SNMPHosts.port')"
          type="text"
          :disabled="isDisable"
          v-model.number="updatedHost.port"
          name="snmp-user-host-port"
          id="snmp-user-host-port"
          :placeholder="$t('SNMP.SNMPHosts.port')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-user-host-port') }"
        />
        <span v-show="errors.has('snmp-user-host-port')" class="help is-danger">
          {{ errors.first('snmp-user-host-port') }}
        </span>
      </div>

      <!--     type -->
      <div class="form-group">
        <label for="snmp-user-host-type">{{ $t('SNMP.SNMPHosts.type') }}:</label>
        <select
          :data-vv-as="$t('SNMP.SNMPHosts.type')"
          data-vv-validate-on="input"
          type="text"
          :disabled="isDisable"
          v-model="updatedHost.type"
          name="snmp-user-host-type"
          id="snmp-user-host-type"
          :placeholder="$t('SNMP.SNMPHosts.type')"
          class="form-control form-control-sm"
          :class="{ input: true, 'is-danger': errors.has('snmp-user-host-type') }"
        >
<!--          <option key="notSelected" value="">-->
<!--            {{ $t('SNMP.notSelected') }}-->
<!--          </option>-->
          <option :key="type" v-for="type in Object.values(types)" :value="type">
            {{ getTypeForDisplayWithI18n(type) }}
          </option>
        </select>
        <span v-show="errors.has('snmp-user-host-type')" class="help is-danger">
          {{ errors.first('snmp-user-host-type') }}
        </span>
      </div>

      <div slot="modal-footer" class="modal-footer justify-content-between">
        <WButton success outline
          customClass="mr-1"
          v-if="!isDisable"
          @click="modalSaveButtonHandler"
          :disabled="errors.any()"
        >
          {{ isNew ? $t('general.create') : $t('general.save') }}
        </WButton>
        <div>
          <WButton danger outline
            customClass="mr-2"
            v-if="!isNew && !isDisable"
            @click="isDeleteOpen = true"
          >
            {{ $t('general.delete') }}
          </WButton>
          <WButton secondary outline
            @click="modalCancelButtonHandler"
          >
            {{ $t('general.close') }}
          </WButton>
        </div>
      </div>
    </modal>

    <delete-dialog
      :is-open="isDeleteOpen"
      :handle-close="closeDeleteDialogHandler.bind(this)"
      :handle-delete="deleteDialogDeleteHandler.bind(this)"
      target-text=""
    />
  </div>
</template>

<script>
import Modal from '../../../../components/Modal.vue';
import DeleteDialog from '../../../../components/delete-dialog.vue';
import snmpHostsService from '../../../../services/Administration/SNMP/snmpHostsService';
import snmpCommunityStringsService
  from '../../../../services/Administration/SNMP/snmpCommunityStringsService';

// const VERSIONS = {
//   V1: '1',
//   V2: '2c',
//   V3: '3'
// };

const VERSIONS = {
  V1: '1',
  V2: '2c',
  V3: '3'
};

const TYPES = {
  TRAPS: 'traps',
};

export default {
  name: 'SNMPHosts',
  components: { Modal, DeleteDialog },
  data() {
    return {
      sort_by: {
        field: 'ip_address',
        order: 1
      },
      isDeleteOpen: false,
      isModalOpen: false,
      isNew: false,
      communityStrings: [],
      // communities: [
      //   {
      //     name: 'testcommunity',
      //     mode: 'read'
      //   }
      // ],
      hosts: [
      ],
      updatedHost: {},
      selectedCommunityStringForUpdatedHostWhenOpenEditModal: '',
      newHost: {
        ip_address: '',
        version: VERSIONS.V2,
        community_string: '',
        port: 192,
        type: TYPES.TRAPS
      }
    };
  },
  computed: {
    showAllSettingsOnSNMPPartitionPages() {
      // используется для задачи DF-77 чтобы временно скрыть нереализованные на бэке функции
      // в подразделах раздела SNMP
      return this.$store.state.showAllSettingsOnSNMPPartitionPages;
    },
    communityStringsSortedByName() {
      const communityStrings = JSON.parse(JSON.stringify(this.communityStrings));
      return communityStrings.sort((a, b) => {
        if (a.community_name.toLowerCase() > b.community_name.toLowerCase()) {
          return 1;
        }
        if (a.community_name.toLowerCase() < b.community_name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
    },
    versions() {
      if (!this.showAllSettingsOnSNMPPartitionPages) {
        // если showAllSettingsOnSNMPPartitionPages false
        // то показываем в списке не все варианты
        // DF-82
        const versionsAsArr = Object.entries(VERSIONS);
        const filtered = versionsAsArr.filter(([key, value]) => {
          return value !== VERSIONS.V1;
        });
        return Object.fromEntries(filtered);
      }
      return VERSIONS;
    },
    types() {
      return TYPES;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    isShowOverPageSpinner() {
      return this.$store.state.loadingSNMPHostsRequestExecuting ||
        this.$store.state.updateSNMPHostsRequestExecuting ||
        this.$store.state.createSNMPHostsRequestExecuting ||
        this.$store.state.deleteSNMPHostsRequestExecuting ||
        this.$store.state.loadingSNMPCommunityStringsRequestExecuting;
    }
  },
  methods: {
    getTypeForDisplayWithI18n(type) {
      try {
        if (type === TYPES.TRAPS) {
          return this.$t('SNMP.SNMPHosts.types.traps')
        } else if (type === TYPES.READ) {
          return this.$t('SNMP.SNMPHosts.types.read')
        } else if (type === TYPES.WRITE) {
          return this.$t('SNMP.SNMPHosts.types.write')
        }
        return type
      } catch (e) {
        return type
      }
    },
    getVersionForDisplayWithI18n(version) {
      try {
        if (version === VERSIONS.V1) {
          return this.$t('SNMP.SNMPHosts.versions.1')
        } else if (version === VERSIONS.V2) {
          return this.$t('SNMP.SNMPHosts.versions.2c')
        } else if (version === VERSIONS.V3) {
          return this.$t('SNMP.SNMPHosts.versions.3')
        }
        return version
      } catch (e) {
        return version
      }
    },
    getCommunityStringNameById(id) {
      try {
        const communityObject = this.communityStrings.find((item) => {
          return item.id === id;
        })
        return communityObject.community_name
      } catch (e) {
        return id
      }
    },
    removeValidationErrorByName(errorName) {
      if (Object.prototype.hasOwnProperty.call(this, 'errors')) {
        if (this.errors.has(errorName)) {
          this.errors.remove(errorName);
        }
      }
    },
    addButtonClickHandler() {
      console.log('click add button');
      this.updatedHost = JSON.parse(JSON.stringify(this.newHost));
      this.openNewModal();
    },
    tableStringClickHandler(host) {
      this.updatedHost = JSON.parse(JSON.stringify(host));
      this.selectedCommunityStringForUpdatedHostWhenOpenEditModal = this.updatedHost.community_string;
      this.openEditModal();
    },
    clearUpdated() {
      this.updatedHost = JSON.parse(JSON.stringify(this.newHost));
      this.selectedCommunityStringForUpdatedHostWhenOpenEditModal = '';
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openModal() {
      this.isModalOpen = true;
    },
    openNewModal() {
      this.isNew = true;
      this.openModal();
    },
    openEditModal() {
      this.isNew = false;
      this.openModal();
    },
    modalSaveButtonHandler() {
      this.$validator.validateAll().then(
        (result) => {
          if (result) {
            if (this.isNew) {
              // console.log('creating new');
              snmpHostsService.create(this);
            } else {
              snmpHostsService.update(this);
              // console.log('saving');
            }
            this.closeModal();
          }
        },
        () => {
          console.warn('Validation failed');
        }
      );
    },
    modalCancelButtonHandler() {
      this.clearUpdated();
      this.closeModal();
    },
    modalDeleteBtuttonHandler() {},
    closeDeleteDialogHandler() {
      this.isDeleteOpen = false;
    },
    deleteDialogDeleteHandler() {
      snmpHostsService.delete(this);
      this.closeDeleteDialogHandler();
      this.closeModal();
    },
    getAll() {
      snmpHostsService.getAll(this);
    }
  },
  created() {
    this.getAll();
    snmpCommunityStringsService.getAll(this)
  }
};
</script>

<style scoped></style>
